import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import AppLogo from 'assets/img/procurementCompass.png'


const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  image: {
    "@media (max-width: 600px)": {
      width: "90%"
    },
    width: "300px",
    float: "right"
  }
}));

export default function NavBar() {
  const classes = useStyles();

  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img src={AppLogo} className={classes.image} alt="Procompass" width="300" />
      </Toolbar>
    </AppBar>)
}