import axios from 'axios';

import { backendApi } from 'utils/constants.js'

export default class BackendApi {
  constructor(token) {
    // Initialize variables
    this.token = token
  }

  async getResponses(workspaceId) {
    const request = {
      method: "get",
      url: `${backendApi.endpoint}/workspaces/${workspaceId}/responses`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.token}`
      }
    }

    const response = await axios(request)

    return response.data
  }

  async saveResponses(workspaceId, responses) {
    const request = {
      method: "put",
      url: `${backendApi.endpoint}/workspaces/${workspaceId}/responses`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.token}`
      },
      data: responses
    }

    const response = await axios(request)

    return response.data
  }

  async calculateTaxes(workspaceId) {
    const request = {
      method: "post",
      url: `${backendApi.endpoint}/workspaces/${workspaceId}/calculate`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.token}`
      }
    }

    const response = await axios(request)

    return response.data
  }

  async submitWorkspace(workspaceId) {
    const request = {
      method: "post",
      url: `${backendApi.endpoint}/workspaces/${workspaceId}/submit`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.token}`
      }
    }

    const response = await axios(request)

    return response.data
  }

}