import React from 'react';
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import routes from "routes.js";

// Custom components
import Navbar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar />
      <Container component="main" className={classes.main} maxWidth="lg">
        <Switch>
          {getRoutes(routes)}
        </Switch>
      </Container>
      <Footer />
    </div>
  );
}