import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import StoreIcon from '@material-ui/icons/Store';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// Custom components
import TcsDialog from './TcsDialog';

// Utils
import {
  destinationProfileValues, finalidadeValues,
  senderProfileValues, natureOperationValues,
  validCombinations
} from '../utils/constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3d7f8b',
    color: theme.palette.common.white,
    fontSize: 13
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  tableSelect: {
    fontSize: 12,
    minWidth: 120
  }
}));

function returnData(supplierKey, items, taxResult, classes, handleChange, status, copyValues, handleOpenDialog, handleChangeDialog) {
  const data = Object.keys(items).map((itemKey, key) => {
    const item = items[itemKey]
    const taxes = taxResult[itemKey]

    const destProfileValuesList = validCombinations.filter(x => x.natOp === item.natOp && x.perfilRem === item.perfilRem).map(x => x.perfilDest)
    const finalidadeValuesList = validCombinations.filter(x =>
      x.natOp === item.natOp && x.perfilRem === item.perfilRem && x.perfilDest === item.perfilDest).map(x => x.finalidade)

    const destProfileList = destinationProfileValues.filter(x => destProfileValuesList.includes(x.value))
    const finalidadeList = finalidadeValues.filter(x => finalidadeValuesList.includes(x.value))

    // Update values if empty
    if (!destProfileValuesList.includes(item.perfilDest) && item.perfilDest) {
      handleChange("", supplierKey, itemKey, 'perfilDest')
    }

    if (!finalidadeValuesList.includes(item.finalidade) && item.finalidade) {
      handleChange("", supplierKey, itemKey, 'finalidade')
    }

    const changesDisabled = (status === 'completed' || status === 'calculating' )
    return {
      id: key,
      ...item,
      completedIcon: (
        <>
          {item.valid ? <DoneIcon color="primary"/> : <WarningIcon color="error" />}
        </>
      ),
      itemNumber: itemKey,
      itemName: item.itemName,
      natureOperationName: natureOperationValues.find(x => x.value === item.natOp)?.name || '',
      senderProfileName: senderProfileValues.find(x => x.value === item.perfilRem)?.name || '',
      destinationProfile: (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">P. Destino</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={item.perfilDest || ''}
            className={classes.tableSelect}
            disabled={changesDisabled}
            onChange={(e) => (handleChange(e.target.value, supplierKey, itemKey, 'perfilDest'))}
          >
            <MenuItem value="" className={classes.tableSelect}>
              Selecione o perfil de destino
            </MenuItem>
            {destProfileList.map((destination, i) => (
              <MenuItem key={i} value={destination.value} className={classes.tableSelect}>
                {`${destination.value} - ${destination.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      goal: (
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Finalidade</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={item.finalidade || ''}
            className={classes.tableSelect}
            disabled={changesDisabled}
            onChange={(e) => (handleChange(e.target.value, supplierKey, itemKey, 'finalidade'))}
          >
            <MenuItem value="" className={classes.tableSelect}>
              Selecione o finalidade
            </MenuItem>
            {finalidadeList.map((goal, i) => (
              <MenuItem key={i} value={goal.value} className={classes.tableSelect}>
                {`${goal.value} - ${goal.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      actions: (
        <>
        <Tooltip title="Copiar Valores">
          <span>
          <IconButton aria-label="expand row" size="small" disabled={changesDisabled} onClick={() => copyValues(supplierKey, itemKey)}>
            <FileCopyIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Detalhes">
          <span>
          <IconButton aria-label="expand row" size="small" disabled={!taxes} onClick={() => {handleChangeDialog({ item, taxes}); handleOpenDialog()}}>
            <AccountBalanceIcon />
          </IconButton>
          </span>
        </Tooltip>
        </>
      )
    };
  })

  return data
}

function Row(props) {
  const { supplierKey, row, handleChange, status, copyValues, taxResult, handleOpenDialog, handleChangeDialog } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const tableData = returnData(supplierKey, row.items, taxResult, classes, handleChange, status, copyValues, handleOpenDialog, handleChangeDialog)

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="h5" color="textSecondary">
            <StoreIcon style={{ marginBottom: -3 }} />
            {supplierKey}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell># Item</StyledTableCell>
                      <StyledTableCell>Nome do item</StyledTableCell>
                      <StyledTableCell>Natureza da Operação</StyledTableCell>
                      <StyledTableCell>Perfil Remetente</StyledTableCell>
                      <StyledTableCell>Perfil Destino</StyledTableCell>
                      <StyledTableCell>Finalidade</StyledTableCell>
                      <StyledTableCell>Ações</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell >
                          {item.completedIcon}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {item.itemNumber}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {item.itemName}
                        </StyledTableCell>
                        <StyledTableCell >{`${item.natOp} - ${item.natureOperationName}`}</StyledTableCell>
                        <StyledTableCell >{`${item.perfilRem} - ${item.senderProfileName}`}</StyledTableCell>
                        <StyledTableCell align="right">{item.destinationProfile}</StyledTableCell>
                        <StyledTableCell align="right">{item.goal}</StyledTableCell>
                        <StyledTableCell >{item.actions}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TcsTable(props) {
  const { data, handleChange, status, copyValues, taxResult } = props

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({});

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>
        <TableBody>
          {Object.keys(data).map((supplierName, i) => (
            <Row key={i} 
              supplierKey={supplierName}
              row={data[supplierName]}
              handleChange={handleChange}
              status={status}
              copyValues={copyValues}
              taxResult={taxResult ? taxResult[supplierName] : {}}
              handleOpenDialog={handleOpenDialog}
              handleChangeDialog={setDialogData}
            />
          ))}
        </TableBody>
      </Table>
      <TcsDialog open={openDialog} handleClickOpen={handleOpenDialog} handleClose={handleCloseDialog} data={dialogData} />
    </TableContainer>
  );
}