import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import MainLayout from "layouts/Main";
import { overrides } from "./themes";

const theme = createMuiTheme({ overrides });

const App = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path="/main" component={MainLayout} />
        <Redirect from="/" to="/main/tax" />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
);

export default App;
