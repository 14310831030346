import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function returnDetails(taxes) {
  const details = []
  let i = 0
  if (taxes?.attributeName) {
    details.push(
      <TableRow key={0}>
        <TableCell component="th" scope="row">
          {taxes.attributeName}
        </TableCell>
        <TableCell align="right">{taxes.tax}</TableCell>
      </TableRow>
    )
  }

  if (taxes?.percentage) {
    i++
    details.push(
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          {'Porcentagem de imposto'}
        </TableCell>
        <TableCell align="right">{`${taxes.percentage}%`}</TableCell>
      </TableRow>
    )
  }
  if (taxes?.details) {
    for (const detailKey in taxes.details) {
      i++
      details.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row">
            {detailKey}
          </TableCell>
          <TableCell align="right">{taxes.details[detailKey]}</TableCell>
        </TableRow>
      )
    }
  }

  return details
}

const useStyles = makeStyles({
});

export default function TaxesDialog(props) {
  const { open, handleClose, data } = props
  const { item, taxes } = data

  const classes = useStyles();

  const rows = returnDetails(taxes)
  return (
    <>
      {
        open
          ?
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              {item.itemName}
            </DialogTitle>
            <DialogContent dividers>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Detalhes</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          :
          null
      }
    </>
  );
}