export const destinationProfileValues = [
  {
    name: 'Indústria',
    value: '1'
  },
  {
    name: 'Consumidor Final Contribuinte',
    value: '3'
  },
  {
    name: 'Atacadista',
    value: '138'
  },
  {
    name: 'Varejista',
    value: '140'
  }
]

export const finalidadeValues = [
  {
    name: 'Compra de material para uso ou consumo',
    value: '43'
  },
  {
    name: 'Compra para insumo',
    value: '202'
  },
  {
    name: 'Compra de bem para o ativo imobilizado',
    value: '220'
  },
  {
    name: 'Compra para comercialização',
    value: '569'
  }
]

export const natureOperationValues = [
  {
    name: 'Venda de produção do estabelecimento',
    value: '120'
  },
  {
    name: 'Venda de mercadoria adquirida ou recebida de terceiros',
    value: '121'
  }
]

export const senderProfileValues = [
  {
    name: 'Industria',
    value: '1'
  },
  {
    name: 'Atacadista',
    value: '2'
  },
  {
    name: 'Varejista',
    value: '3'
  },
  {
    name: 'Importador',
    value: '24'
  },
  {
    name: 'Indústria optante pelo Simples Nacional',
    value: '1,622'
  },
  {
    name: 'Atacadista optante pelo Simples Nacional',
    value: '2,622'
  },
  {
    name: 'Atacadista equiparado à indústria p/ IPI',
    value: '2,89'
  },
  {
    name: 'Varejista optante pelo Simples Nacional',
    value: '3,622'
  },
  {
    name: 'Importador optante pelo Simples Nacional',
    value: '24,622'
  }
]

export const validCombinations = [
  {
    natOp: '120',
    perfilRem: '1',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '120',
    perfilRem: '1',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '120',
    perfilRem: '1',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '120',
    perfilRem: '1',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '120',
    perfilRem: '1',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '120',
    perfilRem: '1,622',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '120',
    perfilRem: '1,622',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '120',
    perfilRem: '1,622',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '120',
    perfilRem: '1,622',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '120',
    perfilRem: '1,622',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '2',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '2',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '2',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '2',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '2',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '3',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '3',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '3',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '3',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '3',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '24',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '24',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '24',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '24',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '24',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '2,622',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '2,622',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '2,622',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '2,622',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '2,622',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '2,89',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '3,622',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '3,622',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '3,622',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '3,622',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '3,622',
    perfilDest: '140',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '24,622',
    perfilDest: '1',
    finalidade: '202'
  },
  {
    natOp: '121',
    perfilRem: '24,622',
    perfilDest: '3',
    finalidade: '43'
  },
  {
    natOp: '121',
    perfilRem: '24,622',
    perfilDest: '3',
    finalidade: '220'
  },
  {
    natOp: '121',
    perfilRem: '24,622',
    perfilDest: '138',
    finalidade: '569'
  },
  {
    natOp: '121',
    perfilRem: '24,622',
    perfilDest: '140',
    finalidade: '569'
  },
]
