// Views
import Tcs from 'views/Tcs/Tcs'

// @material-ui/icons
import ListAltIcon from '@material-ui/icons/ListAlt';

const appRoutes = [
  {
    path: "/tax",
    name: "Taxes Form",
    icon: ListAltIcon,
    component: Tcs,
    layout: "/main"
  }
]

export default appRoutes;