import jwtDecode from "jwt-decode";
import jsonSchema from "jsonschema";

const Validator = jsonSchema.Validator

export function decodeToken(jwtToken) {
  return jwtDecode(jwtToken);
}

export function validateSchema(data, schema, optionalSchemas = []){
  const validator = new Validator()

  for (const optionalSchema of optionalSchemas) {
    validator.addSchema(optionalSchema.value, optionalSchema.path) 
  }

  const result = validator.validate(data, schema)

  return result.errors.length === 0
}
