import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// @material-ui/core components
import Button from '@material-ui/core/Button';
import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/lab components
import Alert from '@material-ui/lab/Alert';

// Custom components
import TcsTable from './components/TcsTable';

// Utils and classes
import { decodeToken  } from 'utils/functions.js'
import BackendApi from 'services/backendApi.js'
import { validateTaxes, validateTaxItem } from './utils/functions'

import 'assets/styles/App.css';

const defaultData = {
  responses: {},
  taxResult: {},
  status: 'pendingResponses'
}

function formatRequest(data) {
  const request = {
    responses: JSON.parse(JSON.stringify(data.responses))
  }

  for (const supplierKey in request.responses) {
    const supplierData = request.responses[supplierKey]
    delete request.responses[supplierKey].data

    for (const itemKey in supplierData.items) {
      const itemData = supplierData.items[itemKey]

      supplierData.items[itemKey] = {
        finalidade: itemData.finalidade,
        perfilDest: itemData.perfilDest
      }
    }
  }

  return request
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function App() {
  const classes = useStyles();

  const search = useLocation().search

  const [data, setData] = React.useState(defaultData);
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [payload, setPayload] = React.useState({});

  // Alert data
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");

  function handleChange(value, supplierName, itemId, prop) {
    const newData = { ...data }

    const item = newData.responses[supplierName].items[itemId]

    item[prop] = value

    item.valid = validateTaxItem(item)

    setData(newData);
  }

  function copyValues(supplierName, itemId) {
    const newData = { ...data }

    const itemToCopy = newData.responses[supplierName].items[itemId]

    for (const supplierName in newData.responses) {
      const items = newData.responses[supplierName].items
      
      for (const itemId in items) {      
        // If the items have the same 2 filters
        if(items[itemId].natOp === itemToCopy.natOp && items[itemId].perfilRem === itemToCopy.perfilRem){
          items[itemId].perfilDest = itemToCopy.perfilDest
          items[itemId].finalidade = itemToCopy.finalidade
          items[itemId].valid = validateTaxItem(items[itemId])
         }
      }
    }

    setData(newData);
  }

  async function getResponses(token, workspaceId) {
    const backendApi = new BackendApi(token)
    setIsLoading(true)
    const data = await backendApi.getResponses(workspaceId)
    for (const supplierKey in data.responses) {
      const items = data.responses[supplierKey].items
      for (const itemKey in items) {
        items[itemKey].valid = validateTaxItem(items[itemKey])
      }
    }
    setIsLoading(false)

    setData(data)
  }

  async function saveData() {
    const backendApi = new BackendApi(token)
    setIsLoading(true)

    const responses = formatRequest(data)
    
    // Validate if it's a valid request
    const validation = validateTaxes(responses)
    if(validation){
      setShowAlert(false)
      await backendApi.saveResponses(payload.OpId, responses)
      data.status = 'responesUpdated'
      setData(data)
    } else {
      setAlertMessage("Informações ausentes obrigatórias")
      setAlertType("warning")
      setShowAlert(true)
    }
    
    setIsLoading(false)
  }

  async function calculateTaxes() {
    const backendApi = new BackendApi(token)
    setIsLoading(true)

    setShowAlert(false)
    await backendApi.calculateTaxes(payload.OpId)
    data.status = 'calculating'
    setData(data)
    
    setIsLoading(false)
  }

  async function submitWorkspace() {
    const backendApi = new BackendApi(token)
    setIsLoading(true)

    setShowAlert(false)
    await backendApi.submitWorkspace(payload.OpId)
    data.status = 'submitted'
    setData(data)
    
    setIsLoading(false)
  }

  function getBottomPanel() {
    return (
      <>
        {
          isLoading ?
            <CircularProgress color="inherit" />
            :
            (
              <>
              { data.status === 'responesUpdated' ? <Alert severity="success">As informações do fornecedor foram atualizadas com sucesso.</Alert> : null }
              { data.status === 'completed' ? <Alert severity="info">Transação concluída</Alert> : null }
              { data.status === 'calculated' ? <Alert severity="success">Impostos calculados com sucesso</Alert> : null }
              { data.status === 'calculating' ? <Alert severity="warning">Os impostos estão sendo calculados</Alert> : null }
              { data.status === 'submitted' ? <Alert severity="success">Formulário enviado com sucesso</Alert> : null }
              <div className={classes.root}>
              { (data.status === 'responesUpdated' || data.status === 'pendingResponses' || data.status === 'calculated') ? 
                <Button variant="contained" color="primary" onClick={saveData}>
                  Salve
                </Button>
                :
                null
              }
              { (data.status === 'responesUpdated') ? 
                <Button variant="contained" color="primary" onClick={calculateTaxes}>
                  Calcular impostos
                </Button>
                :
                null
              }
              { (data.status === 'calculated') ? 
                <Button variant="contained" color="primary" onClick={submitWorkspace}>
                  Enviar fornecedor
                </Button>
                :
                null
              }
              </div>
              </>
            )
        }
        {
          showAlert ?
          <Alert severity={alertType}>{alertMessage}</Alert>
          :
          null
        }
      </>
    )

  }

  // component did mount
  useEffect(() => {
    // code to run on component mount
    const tokenParam = new URLSearchParams(search).get('token')
    const tokenPayload = decodeToken(tokenParam)

    // Save token
    setToken(tokenParam)
    // Save token
    setPayload(tokenPayload)

    // Get data responses
    getResponses(tokenParam, tokenPayload.OpId)

  }, [search])

  return (
    <div className="App">
      <Grid container justify="center">
        <Grid item md={12} xs={12}>
          <TcsTable data={data.responses} status={data.status} handleChange={handleChange} copyValues={copyValues} taxResult={data.taxResult} />
          <br />

          {
            getBottomPanel()
          }
        </Grid>
      </Grid>

    </div>
  );
}

export default App;
