import { validateSchema } from 'utils/functions.js'
import { schema } from 'schemas/taxes'

/**
 * Validates the taxes request
 * @param {Taxes request object} request 
 * @returns boolean if it's valid
 */
export function validateTaxes(request){
  let valid = true

  for (const supplierKey in request.responses) {
    const items = request.responses[supplierKey].items

    for (const itemsKey in items) {
      const item = items[itemsKey]

      const result = validateTaxItem(item)

      valid &= result
    }
  }

  return valid
}

/**
 * Validates a single item for taxes
 * @param {Taxes Item} item
 * @returns True if valid
 */
export function validateTaxItem(item){
  const result = validateSchema(item, schema)

  return result
}